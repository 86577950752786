<template>
  <div class="outer">
    <div class="fixed-header">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h5>
            Editing {{ viewingRoom.title }}  viewing room
            <router-link to="/start" class="float-right relative" style="top:-6px">
              <button class="button">All Rooms</button>
            </router-link>
            <button
              @click="showAudioUploader = true"
              class="button float-right mr-2 relative" style="top:-6px">Upload Audio</button>
            <button
              @click="showImageUploader = true"
              class="button float-right mr-2 relative" style="top:-6px">Upload Images</button>
            <button
              @click="newSection = true"
              class="float-right button mr-2 relative"
              style="top:-6px">
              Add Section
            </button>
            <button @click="saveChanges" class="float-right button mr-2 relative" style="top:-6px">
              Save now
          </button>
            <span class="float-right mr-2 text-small">Saved {{ sinceSaved }} seconds ago</span>
          </h5>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-20">
      <div class="cell small-12 medium-4 pt-5 border-right">
        <label>Title</label>
        <input type="text" v-model="viewingRoom.title" />
        <label>Image position</label>
        <select v-model="viewingRoom.image_right">
          <option value="0">Image on the left</option>
          <option value="1">Image on the right</option>
        </select>
        <router-link :to="`/add-main-image/${$route.params.id}`" class="button">
          Change main image</router-link>
      </div>
      <div class="cell small-12 medium-8 pt-5">
        <!-- Preview area -->
        <div v-if="parseInt(viewingRoom.image_right) === 1" class="outer-box">
          <div class="one-third-width text-center">
            <div class="artist">{{ viewingRoom.title }}</div>
          </div>
          <div class="two-thirds-width inner-space pt-5-small">
            <img
              :src="viewingRoom.uri"
              alt="sdl_installation_one" />
          </div>
        </div>
        <div v-else class="outer-box">
          <div class="two-thirds-width inner-space pt-5-small">
            <img
              :src="viewingRoom.uri"
              alt="sdl_installation_one" />
          </div>
          <div class="one-third-width text-center">
            <div class="artist">{{ viewingRoom.title }}</div>
          </div>
        </div>
        <!-- Blocks -->
        <div
          v-for="(block, index) in viewingRoom.blocks"
          :key="index">
          <app-block-one-render
            v-if="block.block_type === 1"
            :contents="block"
            :type="'edit'"
            @forceUpdate="updatePreview"
            @blockEdit="openEditor" />
          <app-block-two-render
            v-if="block.block_type === 2"
            :contents="block"
            :type="'edit'"
            @forceUpdate="updatePreview"
            @blockEdit="openEditor" />
          <app-block-three-render
            v-if="block.block_type === 3"
            :contents="block"
            :type="'edit'"
            @forceUpdate="updatePreview"
            @blockEdit="openEditor" />
          <app-block-four-render
            v-if="block.block_type === 4"
            :contents="block"
            :type="'edit'"
            @forceUpdate="updatePreview"
            @blockEdit="openEditor" />
          <app-block-five-render
            v-if="block.block_type === 5"
            :contents="block"
            :type="'edit'"
            @forceUpdate="updatePreview"
            @blockEdit="openEditor" />
          <app-block-six-render
            v-if="block.block_type === 6"
            :contents="block"
            :type="'edit'"
            @forceUpdate="updatePreview"
            @blockEdit="openEditor" />
          <app-block-seven-render
            v-if="block.block_type === 7"
            :contents="block"
            :type="'edit'"
            @forceUpdate="updatePreview"
            @blockEdit="openEditor" />
        </div>
      </div>
    </div>
    <app-new-section
      :roomId="viewingRoom.id"
      @closeNewSection="newSection = false"
      v-if="newSection === true" />
    <app-edit-section
      :roomId="viewingRoom.id"
      :blockId="blockInEdit"
      @closeEditSection="blockInEdit = 0"
      v-if="blockInEdit > 0" />
    <app-image-uploader
      :room="viewingRoom.id"
      @closeImageUploader="showImageUploader = false"
      v-if="showImageUploader === true" />
    <app-audio-uploader
      :room="viewingRoom.id"
      @closeAudioUploader="showAudioUploader = false"
      v-if="showAudioUploader === true" />
  </div>
</template>

<script>
import newSection from '@/components/NewSection.vue';
import editSection from '@/components/EditSection.vue';
import blockOneRender from '@/components/BlockOneRender.vue';
import blockTwoRender from '@/components/BlockTwoRender.vue';
import blockThreeRender from '@/components/BlockThreeRender.vue';
import blockFourRender from '@/components/BlockFourRender.vue';
import blockFiveRender from '@/components/BlockFiveRender.vue';
import blockSixRender from '@/components/BlockSixRender.vue';
import blockSevenRender from '../components/BlockSevenRender.vue';
import imageUploader from '../components/ImageUploader.vue';
import audioUploader from '../components/AudioUploader.vue';
import axios from '../axios';

export default {
  name: 'EditRoom',
  data() {
    return {
      viewingRoom: {
        image_position: 0,
      },
      sinceSaved: 0,
      newSection: false,
      showImageUploader: false,
      showAudioUploader: false,
      blockInEdit: 0,
    };
  },
  components: {
    appNewSection: newSection,
    appEditSection: editSection,
    appImageUploader: imageUploader,
    appAudioUploader: audioUploader,
    appBlockOneRender: blockOneRender,
    appBlockTwoRender: blockTwoRender,
    appBlockThreeRender: blockThreeRender,
    appBlockFourRender: blockFourRender,
    appBlockFiveRender: blockFiveRender,
    appBlockSixRender: blockSixRender,
    appBlockSevenRender: blockSevenRender,
  },
  methods: {
    updatePreview() {
      this.viewingRoom = {};
      this.getRoom();
    },
    openEditor(value) {
      this.blockInEdit = value;
    },
    getRoom() {
      const roomId = this.$route.params.id;
      axios.get(`/viewingRooms/getRoom/${roomId}.json?token=${this.$store.state.token}`)
        .then((response) => {
          this.viewingRoom = response.data;
          if (this.viewingRoom.image_right === true) this.viewingRoom.image_right = 1;
          if (this.viewingRoom.image_right === false) this.viewingRoom.image_right = 0;
        });
    },
    saveChanges() {
      const postData = {};
      postData.viewingRoom = this.viewingRoom;
      axios.post(`/viewingRooms/saveUpdates.json?token=${this.$store.state.token}`, postData)
        .then((response) => {
          this.sinceSaved = 0;
          this.viewingRoom = response.data;
          if (this.viewingRoom.image_right === true) this.viewingRoom.image_right = 1;
          if (this.viewingRoom.image_right === false) this.viewingRoom.image_right = 0;
        });
    },
    handleScroll() {
      const foundElements = document.querySelectorAll('.bounceup');
      foundElements.forEach((element) => {
        if (this.isInViewport(element)) {
          element.classList.add('visible');
        }
      });
    },
    isInViewport(element) {
      const distance = element.getBoundingClientRect();
      const elementHeight = element.clientHeight;
      const toBottom = (distance.bottom - elementHeight);
      let isVisible = false;
      if (
        toBottom < (window.innerHeight)
        && distance.top >= -elementHeight
      ) {
        isVisible = true;
      }
      return isVisible;
    },
  },
  mounted() {
    this.getRoom();
    window.setInterval(() => {
      this.saveChanges();
    }, 30000);
    window.setInterval(() => {
      this.sinceSaved += 1;
    }, 1000);
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
  .fixed-header {
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: #FFF;
    padding: 50px 10px;
  }
</style>
