<template>
  <div class="modal">
    <div class="modal-inner">
      <span @click="closeEditSection" class="modal-close">x</span>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-5">
          <h2>Edit section</h2>
        </div>
        <div class="cell small-12 medium-7">
          <h2>Click image to select it for this block</h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-5">
          <div class="grid-x grid-padding-x pt-1">
            <div class="cell small-12">
              <select v-model="blockType">
                <option value=1>Full width image</option>
                <option value=2>Moving image</option>
                <option value=3>Image with text</option>
                <option value=4>Image with details</option>
                <option value=5>Double image with details</option>
                <option value=6>Video</option>
              </select>
            </div>
          </div>
          <div class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Add space above the section</label>
              <select v-model="blockSpace">
                <option value=0>No</option>
                <option value=1>Yes</option>
              </select>
              <span class="help-text">
                Select whether there should be empty space above the section. (This should normally
                be yes, unless you want to "join" two sections together)
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) !== 2"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Is the image / text padded?</label>
              <select v-model="imagePadded">
                <option value=0>No</option>
                <option value=1>Yes</option>
              </select>
              <span class="help-text">Select whether there should be space around the image</span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 1 || parseInt(blockType) === 6"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Title for image / video</label>
              <input type="text" v-model="title" />
              <span class="help-text">
                This will appear above the image / video. Leave blank for no title.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 1 || parseInt(blockType) === 6"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Caption for image / video</label>
              <input type="text" v-model="caption" />
              <span class="help-text">
                This will appear below the image / video. Leave blank for no caption.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 2 || parseInt(blockType) === 4"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Artist</label>
              <input type="text" v-model="artist" />
              <span class="help-text">
                The artist
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 2 || parseInt(blockType) === 4"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Title</label>
              <input type="text" v-model="title" />
              <span class="help-text">
                The artwork title
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 2 || parseInt(blockType) === 4"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Medium</label>
              <input type="text" v-model="medium" />
              <span class="help-text">
                The artwork medium
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 2 || parseInt(blockType) === 4"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Size</label>
              <input type="text" v-model="size" />
              <span class="help-text">
                The artwork size
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 2 || parseInt(blockType) === 4"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Description</label>
              <vue-editor id="editor1" v-model="freeText" :editor-toolbar="customToolbar" />
              <span class="help-text">
                Descriptive text for the artwork.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 2 || parseInt(blockType) === 4"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Image position</label>
              <select v-model="blockLayout">
                <option value="1">Image left</option>
                <option value="2">Image right</option>
              </select>
              <span class="help-text">
                Select whether the static image, below the moving image should be left of the
                details or right of the details.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 3"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Image position</label>
              <select v-model="blockLayout">
                <option value="1">Image left</option>
                <option value="2">Image right</option>
                <option value="3">Image top</option>
                <option value="4">Image bottom</option>
              </select>
              <span class="help-text">
                Select where the image should be positioned.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 3"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Text</label>
              <vue-editor id="editor2" v-model="freeText" :editor-toolbar="customToolbar" />
              <span class="help-text">
                Text to accompany the image
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Left work artist</label>
              <input type="text" v-model="leftArtist" />
              <span class="help-text">
                The artist for the left artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Left work title</label>
              <input type="text" v-model="leftTitle" />
              <span class="help-text">
                The title for the left artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Left work medium</label>
              <input type="text" v-model="leftMedium" />
              <span class="help-text">
                The medium for the left artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Left work size</label>
              <input type="text" v-model="leftSize" />
              <span class="help-text">
                The size for the left artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Left work description</label>
              <vue-editor id="editor3" v-model="leftFreeText" :editor-toolbar="customToolbar" />
              <span class="help-text">
                Descriptive text for the left artwork.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Right work artist</label>
              <input type="text" v-model="rightArtist" />
              <span class="help-text">
                The artist for the right artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Right work title</label>
              <input type="text" v-model="rightTitle" />
              <span class="help-text">
                The title for the right artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Right work medium</label>
              <input type="text" v-model="rightMedium" />
              <span class="help-text">
                The medium for the right artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Right work size</label>
              <input type="text" v-model="rightSize" />
              <span class="help-text">
                The size for the right artwork
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 5"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Right work description</label>
              <vue-editor id="editor4" v-model="rightFreeText" :editor-toolbar="customToolbar" />
              <span class="help-text">
                Descriptive text for the right artwork.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 6"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Video embed code</label>
              <textarea v-model="video" rows="6"></textarea>
              <span class="help-text">
                Video embed code from Vimeo.
              </span>
            </div>
          </div>
          <div
            v-if="parseInt(blockType) === 7"
            class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <label>Text</label>
              <vue-editor id="editor5" v-model="freeText" :editor-toolbar="customToolbar" />
              <span class="help-text">
                Add text.
              </span>
            </div>
          </div>
          <div
            v-if="showBgColour"
            class="grid-x grid-padding-x">
            <div class="cell small-12">
              <label>Choose the background colour</label>
              <input v-model="backgroundColor" type="color" />
              <span class="help-text">
                If there is space around an image, or text in this block the background will be this
                colour.
              </span>
            </div>
          </div>
          <div v-if="selectedImages.length > 0" class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <h2>Selected image(s) for this section</h2>
            </div>
          </div>
          <div v-if="selectedImages.length > 0" class="grid-x grid-padding-x">
            <div
              v-for="(image, siIndex) in selectedImages"
              :key="siIndex"
              class="cell small-6">
              <img :src="image" alt="" />
            </div>
          </div>
          <div v-if="selectedAudio.length > 0" class="grid-x grid-padding-x pt-5">
            <div class="cell small-12">
              <h2>Selected audio for this section</h2>
            </div>
          </div>
          <div v-if="selectedAudio.length > 0" class="grid-x grid-padding-x">
            <div
              v-for="(audio, audIndex) in selectedAudio"
              :key="audIndex"
              class="cell small-6">
              <audio controls>
                <source
                  :src="audio"
                  type="audio/mpeg">
                  Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-7">
          <div class="grid-x grid-padding-x">
            <div
              class="cell small-12 medium-3"
              v-for="(image, index) in images"
              :key="index">
              <img
                @click="selectImage(index)"
                class="selectable-image"
                :src="image"
                alt="" />
            </div>
            <div
              class="cell small-12 medium-3 pt-5"
              v-for="(audio, audioIndex) in soundFiles"
              :key="audio">
              <audio controls>
                <source
                  :src="audio"
                  type="audio/mpeg">
                  Your browser does not support the audio element.
              </audio>
              <label>{{ audio.split('__').pop() }}</label>
              <button
                @click="selectAudio(audioIndex)"
                class="button small">
                Select audio
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12">
          <button v-if="validateForm" @click="updateSection" class="button">
            Update Section
          </button>
          <button v-else disabled class="button disabled">Update Section</button>
          <br />
          <span v-if="!validateForm" class="help-text">
            You must select an image before you can save
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import axios from '../axios';

export default {
  name: 'EditSection',
  props: [
    'blockId',
    'roomId',
  ],
  components: {
    VueEditor,
  },
  data() {
    return {
      blockType: '1',
      blockSpace: '1',
      blockLayout: '0',
      imagePadded: '0',
      backgroundColor: '#FFFFFF',
      artist: '',
      title: '',
      medium: '',
      size: '',
      freeText: '',
      leftArtist: '',
      leftTitle: '',
      leftMedium: '',
      leftSize: '',
      leftFreeText: '',
      rightArtist: '',
      rightTitle: '',
      rightMedium: '',
      rightSize: '',
      rightFreeText: '',
      caption: '',
      video: '',
      images: [],
      selectedImages: [],
      soundFiles: [],
      selectedAudio: [],
      message: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }],
      ],
    };
  },
  computed: {
    showBgColour() {
      let show = false;
      if (parseInt(this.blockType, 10) === 3) show = true;
      if (parseInt(this.blockType, 10) === 4) show = true;
      if (parseInt(this.blockType, 10) === 5) show = true;
      if (parseInt(this.blockType, 10) === 6) show = true;
      if (parseInt(this.imagePadded, 10) === 1) show = true;
      return show;
    },
    validateForm() {
      let readyToSave = false;
      if (
        parseInt(this.blockType, 10) === 1
        || parseInt(this.blockType, 10) === 2
        || parseInt(this.blockType, 10) === 3
        || parseInt(this.blockType, 10) === 4
      ) {
        if (this.selectedImages.length > 0) {
          readyToSave = true;
        }
      }
      if (parseInt(this.blockType, 10) === 5) {
        if (this.selectedImages.length > 1) {
          readyToSave = true;
        }
      }
      if (parseInt(this.blockType, 10) === 6 || parseInt(this.blockType, 10) === 7) {
        readyToSave = true;
      }
      return readyToSave;
    },
  },
  methods: {
    updateSection() {
      const postData = {};
      postData.blockId = this.blockId;
      postData.roomId = this.roomId;
      postData.blockType = this.blockType;
      postData.blockSpace = this.blockSpace;
      postData.imagePadded = this.imagePadded;
      postData.blockLayout = this.blockLayout;
      postData.backgroundColor = this.backgroundColor;
      postData.images = this.selectedImages;
      postData.title = this.title;
      postData.caption = this.caption;
      postData.video = this.video;
      postData.audio = this.selectedAudio;
      postData.artist = this.artist;
      postData.medium = this.medium;
      postData.size = this.size;
      postData.freeText = this.freeText;
      postData.leftArtist = this.leftArtist;
      postData.leftTitle = this.leftTitle;
      postData.leftMedium = this.leftMedium;
      postData.leftSize = this.leftSize;
      postData.leftFreeText = this.leftFreeText;
      postData.rightArtist = this.rightArtist;
      postData.rightTitle = this.rightTitle;
      postData.rightMedium = this.rightMedium;
      postData.rightSize = this.rightSize;
      postData.rightFreeText = this.rightFreeText;
      postData.linkButtonText = this.linkButtonText;
      postData.linkButtonLink = this.linkButtonLink;
      postData.blockOrder = this.blockOrder;
      axios.post(`/blocks/update.json?token=${this.$store.state.token}`, postData)
        .then(() => {
          this.$emit('closeEditSection');
        });
    },
    selectImage(index) {
      if (parseInt(this.blockType, 10) === 5) {
        if (this.selectedImages.length > 1) {
          this.selectedImages.splice(0, 1);
        }
        this.selectedImages.push(this.images[index]);
      } else {
        this.selectedImages = [this.images[index]];
      }
    },
    selectAudio(index) {
      this.selectedAudio = [this.soundFiles[index]];
    },
    closeEditSection() {
      this.$emit('closeEditSection');
    },
    getImages() {
      axios.get(`/images/getAll/${this.roomId}.json?token=${this.$store.state.token}`)
        .then((response) => {
          this.images = response.data;
        });
    },
    getAudio() {
      axios.get(`/soundFiles/getAll/${this.roomId}.json?token=${this.$store.state.token}`)
        .then((response) => {
          this.soundFiles = response.data;
        });
    },
    getBlock() {
      axios.get(`/blocks/getToEdit/${this.blockId}.json?token=${this.$store.state.token}`)
        .then((response) => {
          let imagePadded = 0;
          if (response.data.image_padded === true) imagePadded = 1;
          let blockSpace = 0;
          if (response.data.block_space === true) blockSpace = 1;
          this.blockType = response.data.block_type;
          this.blockSpace = blockSpace;
          this.imagePadded = imagePadded;
          this.backgroundColor = response.data.background_color;
          this.artist = response.data.artist;
          this.title = response.data.title;
          this.medium = response.data.medium;
          this.size = response.data.size;
          this.freeText = response.data.free_text;
          this.leftArtist = response.data.left_artist;
          this.leftTitle = response.data.left_title;
          this.leftMedium = response.data.left_medium;
          this.leftSize = response.data.left_size;
          this.leftFreeText = response.data.left_free_text;
          this.rightArtist = response.data.right_artist;
          this.rightTitle = response.data.right_title;
          this.rightMedium = response.data.right_medium;
          this.rightSize = response.data.right_size;
          this.rightFreeText = response.data.right_free_text;
          this.caption = response.data.caption;
          this.video = response.data.video;
          this.selectedImages[0] = response.data.image_one_uri;
          if (response.data.image_two_uri) this.selectedImages[1] = response.data.image_two_uri;
          if (response.data.audio) this.selectedAudio[0] = response.data.audio;
          this.blockOrder = response.data.block_order;
        });
    },
  },
  mounted() {
    this.getBlock();
    this.getImages();
    this.getAudio();
  },
};
</script>

<style lang="scss">
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.6);
  }
  .modal-inner {
    position: absolute;
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;
    z-index: 100;
    background-color: #FFF;
    padding: 30px 20px 20px 20px;
    overflow: scroll;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    color: #333;
    font-size: 22px;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 25px;
    border-radius: 50%;
    border: 2px solid #333;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #CCC;
    }
  }
  .selectable-image {
    cursor: pointer;
    border: 2px solid #FFF;

    &:hover,
    &:focus {
      border: 2px solid #888;
    }

    &.active {
      border: 2px solid #888;
    }
  }

  .selectable-audio {
    border: 2px solid #FFF;

    &.active {
      border: 2px solid blue;
    }
  }
</style>
