<template>
  <div class="modal">
    <div class="modal-inner">
      <span @click="closeUploader" class="modal-close">X</span>
      <div class="upload-zone DashboardContainer"></div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const Transloadit = require('@uppy/transloadit');

export default {
  name: 'ImageUploader',
  props: [
    'room',
  ],
  methods: {
    closeUploader() {
      this.$emit('closeImageUploader');
    },
    saveToServer(fileData) {
      const postData = {};
      postData.images = fileData;
      postData.roomId = this.room;
      axios.post(`/Images/addImages.json?token=${this.$store.state.token}`, postData)
        .then((response) => {
          const images = response.data;
          this.$store.commit('addImages', images);
          this.$emit('closeImageUploader');
        });
    },
  },
  mounted() {
    new Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 100),
        maxNumberOfFiles: 20,
        minNumberOfFiles: 1,
        allowedFileTypes: null,
      },
    })
      .use(Dashboard, {
        trigger: '.UppyModalOpenerBtn',
        inline: true,
        target: '.upload-zone',
        replaceTargetContent: true,
        showProgressDetails: true,
        note: 'Upload images',
        height: 500,
        width: '100%',
        metaFields: [
          { id: 'name', name: 'Name', placeholder: 'file name' },
          { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' },
        ],
        browserBackButtonClose: true,
        proudlyDisplayPoweredByUppy: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: '3ea7b59e618a4e15867771df189fa6c5',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveToServer(result);
      });
  },
};
</script>
